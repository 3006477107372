<script>
export default {
  name: "resource-filter",

  props: {
    onFilterRequest: {
      type: Function,
    },

    onReset: {
      type: Function,
    },

    startTypeIds: {
      type: Array,
      default() {
        return [];
      },
    },

    startScenarioIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      searchString: "",
      orderby: "relevance",
      typeIds: this.startTypeIds,
      scenarioIds: this.startScenarioIds,
    };
  },

  methods: {
    onSubmit(e) {
      if (e) e.preventDefault();

      if (this.searchString) {
        dataLayer.push({
          event: "genericEvent",
          genericCategory: "Resource Filter > Search Keyword",
          genericAction: "Submit",
          genericLabel: this.searchString,
        });
      }

      if (this.onFilterRequest) this.onFilterRequest(this.getFilterData());
    },

    onClear(e) {
      if (e) e.preventDefault();

      this.searchString = "";
      this.typeIds = this.startTypeIds;
      this.scenarioIds = this.startScenarioIds;
      this.orderby = "relevance";

      if (this.onReset) this.onReset(this.getFilterData());
    },

    getFilterData() {
      return {
        searchString: this.searchString,
        orderby: this.orderby,
        typeIds: this.typeIds,
        scenarioIds: this.scenarioIds,
      };
    },
  },
};
</script>
