/**
 * Open/Close drawers
 *
 * Seen in footer nav on mobile
 */
let $win = $(window);

// Boot all accordion instances
$(document).ready(function() {
  $(".js-marquee-v2-toggle").each(function(i, el) {
    $(el).marqueeV2Toggle();
  });
});

// Accordion Module Logic
let module = function(el) {
  let $module = $(el);
  const delay = $module.data("toggle-delay");
  const hasImages = $module.data("has-images");
  const $images = $module.find(".vv-visual");

  let active = 0;

  if (!hasImages) return;

  setInterval(toggleImage, 3000 + delay / 2);

  function toggleImage() {
    $images.eq(active).css("opacity", 0);
    active = active == 0 ? 1 : 0;
    $images.eq(active).css("opacity", 1);
  }
};

// Booting an ad hoc plugin
if (window.jQuery) {
  jQuery.fn.extend({
    marqueeV2Toggle: function() {
      module(this[0]);
      return this;
    },
  });
}
