<script>
export default {
  name: "qa-accordion",

  props: {
    question: {
      type: String
    },

    startClosed: {
      type: Boolean,
      default: true
    }
  },

  created() {
    this.open = !this.startClosed;
  },

  data() {
    return {
      open: false
    };
  },

  methods: {
    toggle() {
      this.open = !this.open;

      dataLayer.push({
        event: "genericEvent",
        genericCategory: `FAQ Toggle > ${this.open ? "Open" : "Close"}`,
        genericAction: "Click",
        genericLabel: this.question
      });
    }
  }
};
</script>
