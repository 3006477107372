<!--
SurveyPopup
-->
<script>
import Window from "window-event-mediator";
import Cookies from "js-cookie";

const COOKIE_KEY = "SurveyPopupDismissed";
const DISPLAY_PERCENTAGE = 0.6;

export default {
  name: "SurveyPopup",

  data() {
    return {
      display: false,
      showForm: false,
      hasBeenDisplayed: !!Cookies.get(COOKIE_KEY),
    };
  },

  mounted() {
    if (this.hasBeenDisplayed) return;
    if (!window || typeof window === "undefined") return;

    Window.on("scroll", this.onScroll);
  },

  destroyed() {
    Window.off("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      if (this.hasBeenDisplayed) return;
      const scrollPercent =
        (window.pageYOffset + window.innerHeight) / document.body.clientHeight;
      if (!this.display && scrollPercent >= DISPLAY_PERCENTAGE) {
        this.display = true;
      }
    },

    formButtonClicked() {
      this.showForm = true;
      // Fire custom event
      dataLayer.push({
        event: "genericEvent",
        genericCategory: `Survey > View Form`,
        genericAction: "Click",
        genericLabel: window.location.href,
      });
    },

    close() {
      this.hasBeenDisplayed = true;
      Cookies.set(COOKIE_KEY, "true", {
        path: "/",
        expires: 1, // one day
      });

      this.display = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.survey-popup
  position fixed
  top 0
  right 0
  left 0
  bottom 0
  z-index 100
  display flex
  align-items center
  justify-content center
  pointer-events none
  padding-top 80px
  default-transition opacity, time-reg
  opacity 0

  &.display
    pointer-events all
    opacity 1

    .content-container
      transform scale(1)

.content-container
  background white
  fluid padding-top, 80, 40
  fluid padding-bottom, 80, 40
  box-shadow 0 0 25px black
  width 85%
  min-height 30vh
  max-width 800px
  position relative
  default-transition min-height transform, time-reg
  transform scale(0.9)

  &.showing-form
    min-height 80vh

.close-button
  position absolute
  right 30px
  top 30px
  font-weight bold
  font-size 22px
  default-transition transform, time-fast
  cursor pointer
  z-index 2

  +whenActive()
    transform scale(1.1)

  @media(max-width tablet)
    border-radius 50%
    background white
    padding 9px
    box-shadow 0 0 8px rgba(black, 0.2)
    width 35px
    height 35px
    text-align center
    top -17px
    right -17px

.button
  margin-top 40px

.color-screen
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  width 100%
  height 100%
  background rgba(black, 0.85)
</style>
