<template lang="pug">
a.card.resource-card.shadow-regular.hover-shadow(
  :href="url"
  :target="externalUrl ? '_blank' : null"
  :rel="externalUrl ? 'noopener noreferrer' : null"
  :data-gtm="`Resource Card > ${eyebrow}`" 
  :data-gtm-value="post.title.rendered"
)

    span.eyebrow(v-if="eyebrow" v-html="eyebrow")

    .separator.orange.small

    .image-container(v-if="thumb")
        visual.image(
            :image="image"
            background="cover"
            :alt="thumb.title"
            :aspect="16/9"
        )
          .icon-play(v-if="eyebrow && eyebrow == 'Video'")

    p.date.text-body-copy-small(v-if="type == 'post' && date") {{ date }}


    .title(v-if="post.title" v-html="post.title.rendered")

    //- Peer-Reviewed Article 
    template(v-if="resourceTypeSlug == 'peer-reviewed-article'")
      .tiny-details.small-margin(v-if="resourceAuthors" v-html="resourceAuthors")

      .journal-details(v-if="publicationYear || journalName")
        span.journal-name(v-if="journalName" v-html="journalName+','")
        span.journal-year(v-if="publicationYear" v-html="publicationYear")

    p.excerpt.text-body-copy-small(v-if="post.excerpt" v-html="post.excerpt.rendered")


    .external-link-label(
      style="margin-top: 22px"
      v-if="resourceTypeSlug == 'peer-reviewed-article'"
    )
      span(class="icon-external-link")
      label External link


    //- AUTHOR
    .author-details(v-if="type == 'post' && author")
        visual.author-image.border-circle(
            v-if="author.portrait"
            :image="author.portrait"
            background="cover"
            alt="TODO ALT"
            :aspect="16/9"
        )

        span.author-name(v-if="author.name" v-html="author.name")

    .tiny-details(v-if="timeframe && resourceTypeSlug == 'presentation-material'" v-html="timeframe")

    //- External Resource
    template(v-if="resourceTypeSlug == 'external-resource'")
      .tiny-details(v-if="externalSourceDescription" 
        v-html="externalSourceDescription"
      )

      .external-source-details
        visual.source-logo(
          v-if="externalSourceLogo"
          :image="externalSourceLogo"
        )
        .external-link-label
          label External link

    .resource-icons(v-if="resourceTypeIcon && hideIconTypes.indexOf(resourceTypeSlug) < 0")
      img(:src="resourceTypeIcon" :alt="'Resource type: '+eyebrow")

</template>

<script>
import get from "lodash.get";

export default {
  name: "resource-card",

  props: {
    post: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      hideIconTypes: [
        "external-resource",
        "presentation-material",
        "peer-reviewed-article",
      ],
    };
  },

  computed: {
    author() {
      return get(this.details, "author");
    },

    url() {
      if (this.externalUrl) return this.externalUrl;
      return this.post.link;
    },

    externalUrl() {
      return get(this.details, "external_url");
    },

    thumb() {
      return this.post.listing_thumbnail;
    },

    // Images come in as either a JSON string or a URL string
    // Try to parse the json, and if that fails just return the string
    image() {
      if (!this.thumb || !this.thumb.image) return null;
      try {
        const image = JSON.parse(this.thumb.image);
        return image;
      } catch (e) {
        return this.thumb.image;
      }
    },

    details() {
      return get(this.post, "post_details");
    },

    date() {
      return get(this.details, "date");
    },

    type() {
      return this.post.type;
    },

    eyebrow() {
      if (this.type === "post") return "Blog";
      else return this.resourceTypeName;
    },

    resourceType() {
      return get(this.details, ["resource_types", 0]);
    },

    resourceTypeSlug() {
      return get(this.resourceType, "slug");
    },

    resourceTypeName() {
      return get(this.resourceType, "name");
    },

    timeframe() {
      return get(this.details, "timeframe");
    },

    resourceTypeIcon() {
      // TODO: Use a default blog post icon is we need one.
      // Otherwise, there should be no `type` icon for blog posts
      if (this.type === "post") return null;

      return get(this.resourceType, "icon");
    },

    externalSourceLogo() {
      let img = get(this.details, "external_source_logo");
      if (!img) return null;

      return JSON.parse(img);
    },

    externalSourceDescription() {
      return get(this.details, "external_source_description");
    },

    journalName() {
      return get(this.details, "journal_name");
    },

    publicationYear() {
      return get(this.details, "publication_year");
    },

    resourceAuthors() {
      return get(this.details, "resource_authors");
    },
  },
};
</script>
