<script>
import Window from "window-event-mediator";
import Hamburger from "./Hamburger";
import EventBus from "@/EventBus";

// Scroll pixels below top when docking nav will be triggered
const DOCKING_POINT = 50;

export default {
  name: "main-header",

  components: {
    hamburger: Hamburger
  },

  data() {
    return {
      isDocked: false,
      mobileNavOpen: false,
      announcementBanner: false
    };
  },

  mounted() {
    this.announcementBanner = document.getElementsByClassName(
      "announcement-banner"
    ).length;
    Window.on("scroll", this.onScroll);
    EventBus.$on("mobile-nav", this.onMobileNavChange);
    EventBus.$on("announcement-close", this.onAnnouncementClose);
    this.onScroll();
  },

  destroyed() {
    Window.off("scroll", this.onScroll);
  },

  methods: {
    onScroll(e) {
      this.isDocked =
        !!this.announcementBanner || window.pageYOffset >= DOCKING_POINT;
    },

    onMobileNavChange(isOpen) {
      this.mobileNavOpen = isOpen;
    },

    onAnnouncementClose() {
      console.log("HEADER: close announcement");
      // this.announcementBanner = false;
      // this.onScroll();
    }
  }
};
</script>
