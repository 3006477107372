<!--
announcement-banner
-->

<template lang="pug">
.announcement-banner
</template>

<script>
import EventBus from "@/EventBus";

export default {
  name: "announcement-banner",

  data() {
    return {
      closed: false,
      hidden: false,
    };
  },

  methods: {
    onClose() {
      EventBus.$emit("announcement-close");
      this.closed = true;
      
      setTimeout(() => {
        this.hidden = true;
      }, 500);
    }
  }
};
</script>

<style lang="stylus" scoped>
.announcement-banner
  padding-top 85px
  padding-bottom 32px
  fluid padding-left, 40, 26, null, 580
  fluid padding-right, 40, 26, null, 580
  default-transition padding height transform, time-reg

  @media (max-width: tablet)
    padding-top 76px
    padding-bottom 16px

  .content-max-w
    max-width 1400px
    position relative
    padding-right 30px

  a
    font-weight bold
    color white
    default-transition opacity, time-fast

    +whenActive()
      color white

  &.hidden
    display none

  &.closed
    height 0
    padding-top 0
    padding-bottom 0

  .close
    position absolute
    right 0
    top 10px
    font-size 20px
    font-weight 300
    cursor pointer
    default-transition transform opacity, time-fast
    border none

    @media (max-width: tablet)
      top 4px
      font-size 22px

    +whenActive()
      transform scale(1.2)
    
    &:focus-visible
      border-radius 3px
      outline 2px solid lighten(blue, 8%)
</style>