<script>
/**
 * TODO
 *
 * X Hide original search results if filter changes
 * X Disable form when requesting
 * _ Stub out Card component styles
 * _ Pass data through AJAX request
 * _ Support hide filter checkbox (visibility:hidden, height 0)
 */

import ResourceFilter from "./filter";
import ResourceCard from "./card";

const API_BASE = `${location.origin}/wp-json/wp/v2/posts`;

import axios from "axios";
import clone from "lodash.clone";

const POSTS_PER_PAGE = 12;

export default {
  name: "resource-listing",

  components: {
    ResourceFilter,
    ResourceCard,
  },

  props: {
    hasMorePostsInitial: {
      type: Boolean,
      defaullt: false,
    },

    noPostsOnLoad: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // If there is more data to display
      hasMorePosts: false,

      // Request locked when running XHR
      requestLocked: false,

      // Pagination counter
      currentPage: 2,

      // Stores all AJAXd post objects
      posts: [],

      // Hode server-rednered posts if we're searching a new filter
      hideOriginalPosts: false,

      // First request is stored until the user hits the View More button
      // so we know if we should hide the button or not
      storedPosts: [],

      // Flag when all posts for this query have been displayed
      noMorePages: false,
    };
  },

  mounted() {
    this.hasMorePosts = this.hasMorePostsInitial;
  },

  methods: {
    onSearchRequest(formData) {
      this.resetPosts();
      this.hideOriginalPosts = true;
      this.currentPage = 1;
      this.noMorePages = true;
      this.loadMorePosts(formData);
    },

    loadMorePosts(formData = {}) {
      if (this.requestLocked) return;

      this.requestLocked = true;

      if (!formData || formData.target)
        formData = this.$refs.filter.getFilterData();

      let params = this.buildFormParams(formData);

      axios
        .get(API_BASE, { params })
        .then(this.onPostsReceived)
        .catch(this.onPostsFailed)
        .finally(() => {
          this.requestLocked = false;
        });
    },

    onPostsReceived({ headers, data }) {
      // Add new posts to listing
      this.posts = this.posts.concat(data);

      // Increment page count if we've received new posts
      this.currentPage++;

      // Check for more pages
      this.noMorePages = headers["x-wp-totalpages"] < this.currentPage;
    },

    onPostsFailed(res) {
      // console.log(res);
      this.noMorePages = true;
    },

    resetPosts() {
      this.currentPage = 2;
      this.hasMorePosts = this.hasMorePostsInitial;
      this.posts = [];
      this.noMorePages = false;
      this.hideOriginalPosts = false;
    },

    buildFormParams(formData) {
      let params = {
        // include resources as well as posts
        type: ["resource"],
        per_page: POSTS_PER_PAGE,
        offset: (this.currentPage - 1) * POSTS_PER_PAGE,
      };

      if (formData) {
        // Search string
        if (formData.searchString) {
          params.search = formData.searchString;
          params.orderby = formData.orderby; // can only sort by relevance with search term
        }

        let typeIds = clone(formData.typeIds);

        // Taxonomy IDs
        if (typeIds && typeIds.length) {
          // Append `post` type if selected
          let postIndex = typeIds.indexOf("post");

          if (postIndex >= 0) {
            typeIds.splice(postIndex, 1);

            // If no resource types, remove "resource" from the query
            // if (!typeIds || typeIds.length <= 0) {
            //   params.type = ["post"];
            // } else {
            //   params.type.push("post");
            // }
          }

          if (typeIds.length) {
            typeIds = typeIds.join(",");
            params["resource-types"] = typeIds;
          }
        } else {
          // params.type.push("post");
        }

        if (formData.scenarioIds && formData.scenarioIds.length)
          params["category"] = formData.scenarioIds.join(",");
      }

      return params;
    },
  },

  computed: {
    showNoPostsMessage() {
      if (this.requestLocked) return false;

      if (this.noPostsOnLoad && !this.hideOriginalPosts) return true;

      if (this.hideOriginalPosts && !this.posts.length) return true;

      return false;
    },
  },
};
</script>
