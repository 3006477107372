<script>
import Cookies from "js-cookie";

const COOKIE_KEY = "OptanonAlertBoxClosed";

export default {
  name: "cookie-banner",

  data() {
    return {
      hidden: true,
      open: false,
    };
  },

  mounted() {
    const hasAgreed = Cookies.get(COOKIE_KEY);
    if (!hasAgreed) this.show();
  },

  methods: {
    hide() {
      Cookies.set(COOKIE_KEY, new Date());
      this.open = false;

      setTimeout(() => {
        this.hidden = true;
      }, 500);
    },

    show() {
      this.hidden = false;
      this.open = true;
    },

    close(e) {
      this.hide();
    },
  },
};
</script>
