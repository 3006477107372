// require('normalize.css')
import "@babel/polyfill";

require("./stylus/app.styl");
import balanceText from "./js/balance-text";

import "./stylus/typography/fonts.styl";

require("velocity-animate");

import Vue from "vue";

/**
 * GLOBAL COMPONENTS
 */
require("vue-visual/index.css");
import VueVisual from "vue-visual";
Vue.component("visual", VueVisual).options.setDefaults({
  load: "visible",
  inViewportRootMargin: "1000px 0px",
});

import VueHeightTweenTransition from "vue-height-tween-transition";
import "vue-height-tween-transition/index.css";
Vue.component("height-tween", VueHeightTweenTransition);

import VDragged from "v-dragged";
Vue.use(VDragged);

import "./js/marquee-home-v2.js";

/**
 * MODULES
 */
import MainHeader from "./components/MainHeader";
Vue.component("main-header", MainHeader);

import MobileNav from "./components/MobileNav";
Vue.component("mobile-nav", MobileNav);

import QAAccordion from "./components/QAAccordion";
Vue.component("qa-accordion", QAAccordion);

import ImageSlider from "./components/ImageSlider";
Vue.component("image-slider", ImageSlider);

import FeatureContentSlider from "./components/FeatureContentSlider";
Vue.component("feature-content-slider", FeatureContentSlider);

import ResourceListing from "./components/resource/listing";
Vue.component("resource-listing", ResourceListing);

import CookieBanner from "./components/CookieBanner";
Vue.component("cookie-banner", CookieBanner);

import AnnouncementBanner from "./components/AnnouncementBanner";
Vue.component("announcement-banner", AnnouncementBanner);

import SurveyPopup from "./components/SurveyPopup";
Vue.component("survey-popup", SurveyPopup);

import inViewportDirective from "vue-in-viewport-directive";
inViewportDirective.defaults.once = true;
Vue.directive("in-viewport", inViewportDirective);

Vue.config.productionTip = false;

var app = new Vue({
  el: "#app",
});

const bt = balanceText();
const els = document.getElementsByClassName("balance-text");
for (var i = 0; i < els.length; i++) {
  bt(els[i], { watch: true });
}

// Simple JS modules, not components
// Mostly reused from the Brand site code
// require("./js/back-to-top");
// require("./js/accordion");

$(document).ready(function() {
  /**
   * Check links inside of Richt Text and Article body copy
   * for extenal URLs. If they're external, force a new tab
   */
  $(".wysiwyg a").each(function() {
    var a = new RegExp("/" + window.location.host + "/");

    if (!a.test(this.href)) {
      $(this).click(function(event) {
        event.preventDefault();
        event.stopPropagation();
        window.open(this.href, "_blank");

        dataLayer.push({
          event: "genericEvent",
          genericCategory: "Rich Text > Link",
          genericAction: "Click",
          genericLabel: this.href,
        });
      });
    } else {
      this.setAttribute("data-gtm", "Rich Text > Link");
      this.setAttribute("data-gtm-value", this.href);
    }
  });

  /**
   * Attach analytic events attributes to the main navigation links
   * and search form
   */
  $(".main-navigation a, .mobile_nav a").each(function() {
    this.setAttribute("data-gtm", "Main Navigation > Link");
    if (this.textContent) this.setAttribute("data-gtm-value", this.textContent);
  });

  $("#main-footer .main-navigation a, #main-footer .legal-menu a").each(
    function() {
      this.setAttribute("data-gtm", "Footer Navigation > Link");
      if (this.textContent)
        this.setAttribute("data-gtm-value", this.textContent);
    }
  );

  $("a.site-branding").each(function() {
    this.setAttribute("data-gtm", "Main Navigation > Link");
    this.setAttribute("data-gtm-value", "Home");
  });

  $("form.search-form").each(function() {
    $(this).submit(function(e) {
      let val = $(e.target)
        .find('input[type="search"]')
        .val();

      dataLayer.push({
        event: "genericEvent",
        genericCategory: "Search Form > Search",
        genericAction: "Submit",
        genericLabel: val,
      });
    });
  });

  $("form.wpcf7-form").each(function() {
    this.setAttribute("data-gtm", "Contact Form");
  });

  /**
   * Go to next lbock when clicked
   */
  $(".js-go-to-next-block").each(function() {
    this.addEventListener("click", function(e) {
      var next = $(this)
        .closest(".module")
        .next();

      if (!next || !next.length) return;

      $("html, body").animate(
        { scrollTop: next.offset().top - 80 },
        { duration: 1000 }
      );
    });
  });

  // Header menu
  $(".menu-item-has-children").on("focusin pointerenter", (e) => {
    const a = $(e.currentTarget).children("a[aria-expanded]");

    a.attr("aria-expanded", true);
  });

  $(".menu-item-has-children").on("focusout pointerleave", (e) => {
    const a = $(e.currentTarget).children("a[aria-expanded]");

    a.attr("aria-expanded", false);
  });
});
