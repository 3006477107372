<script>
export default {
  name: "image-slider",

  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },

    total: {
      type: Number,
      default: 0,
    },

    aspects: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      active: 0,
      dragPosition: 0,
      intervalID: null,
    };
  },

  mounted() {
    if(!this.autoplay) return;

    this.intervalID = setInterval(this.increment, 4000);
  },

  destroyed() {
    clearInterval( this.intervalID );
  },

  methods: {
    onDragged({ first, last, clientX }) {
      if (first) {
        this.dragPosition = clientX;
      }
      if (last) {
        if (clientX < this.dragPosition) {
          this.decrement();
        } else {
          this.increment();
        }
      }
    },
    goToIndex(index) {
      this.setActive(index);
    },

    increment() {
      this.setActive(this.active + 1);
    },

    decrement() {
      this.setActive(this.active - 1);
    },

    setActive(newActive) {
      if (newActive >= this.total) newActive = 0;
      if (newActive < 0) newActive = this.total - 1;
      this.active = newActive;
    },
  },
};
</script>
