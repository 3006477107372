<script>
import EventBus from "@/EventBus";

export default {
  data() {
    return {
      isOpen: false,
    };
  },

  mounted() {
    EventBus.$on("mobile-nav", this.onChange);
  },

  methods: {
    onChange(isOpen) {
      this.isOpen = isOpen;

      const bodyEl = document.getElementsByTagName("body")[0];
      bodyEl.style.overflow = this.isOpen ? "hidden" : null;
    },

    onScreenClick() {
      EventBus.$emit("mobile-nav", false);
    },
  },
};
</script>
