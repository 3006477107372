<template lang='pug'>
button.Hamburger(
  @click='toggle()'
  :class='{ open : isOpen }'
  aria-label="Toggle mobile navigation menu"
)
  .bar(v-for='i in 3')
</template>

<script>
import EventBus from '@/EventBus'

export default {

  data() { return {
    isOpen: false,
    loginOpen: false, 
    mobileOpen: false,
  }},

  mounted() {
    EventBus.$on('mobile-nav', this.navToggle)
    EventBus.$on('mobile-login-nav-toggle', this.loginToggle)
  },

  methods: {
    toggle() {
      if(this.loginOpen || (this.loginOpen && this.mobileOpen)) {
        EventBus.$emit('mobile-login-nav-close')
        this.loginOpen = false
        this.mobileOpen = false
        EventBus.$emit('mobile-nav', false)
      } else {
        this.mobileOpen = !this.mobileOpen
        EventBus.$emit('mobile-nav', this.mobileOpen)
      }

      this.checkState()
    },

    navToggle(isOpen) {
      this.mobileOpen = isOpen;
      this.checkState()
    },

    loginToggle() {
      this.loginOpen = !this.loginOpen
      this.checkState()
    },

    checkState() {
      this.isOpen = (this.loginOpen || this.mobileOpen)
    }
  },

}
</script>